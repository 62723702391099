import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/layout";
import { Seo } from "../../components/shared/seo";
import AllArtistsNavbar from "../../components/artists/allArtistsNavbar";
import { ArtistGridItem } from "../../components/shared/artistGridItem";
import { gridStyle } from "../../utils/gridStyles";
import { responsiveTextBasic, stickyNav } from "../../components/shared/styles";
const ArtistsPage = (props) => {
  // --->  Data <--- //
  const artists = props.data.allStrapiArtist.nodes;

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      {/* --------- Artist/Work NAVBAR ---------  */}
      <AllArtistsNavbar
        location={props.location}
        classes={`${responsiveTextBasic} mb-xl ${stickyNav} z-[29]`}
      />
      {/* ----- Artists Grid ----- */}
      <div className={`${gridStyle}`}>
        {artists &&
          artists.map((artist, index) => {
            return (
              <ArtistGridItem
                key={index}
                artist={artist}
                classes={""}
                location={props.location}
              />
            );
          })}
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ArtistsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiArtist(sort: { fields: lastName }) {
      nodes {
        slug
        firstName
        lastName
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  #width: 700
                  height: 700
                  quality: 100
                  #layout: FULL_WIDTH
                  #transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default ArtistsPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Artists"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
