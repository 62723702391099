import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { LinkArtist, LinkIcon, OverlayImage } from "./links";
import { motion, useInView, useAnimation } from "framer-motion";

export const ArtistGridItem = ({ artist, classes, location }) => {
  const { slug } = artist;
  const artistHerolImage = getImage(
    artist.heroImage.imageFile.localFile.childImageSharp
  );

  // Animation inView
  // IN-VIEW SHOW
  const pageContainerRef = React.useRef(null);
  const isInView = useInView(pageContainerRef, { once: false, amount: 0.1 });
  const inViewControls = useAnimation();
  const inViewVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 5,
    },
  };
  React.useEffect(() => {
    if (isInView) {
      inViewControls.start("visible");
    } else if (!isInView) {
      inViewControls.start("hidden");
    }
  }, [inViewControls, isInView]);

  return (
    <div className={`${classes} flex flex-col justify-between h-full w-full`}>
      <LinkArtist
        a={artist.slug}
        classes={
          "relative h-full flex flex-col justify-center items-center capitalize"
        }
      >
        <motion.div
          ref={pageContainerRef}
          animate={inViewControls}
          initial={"hidden"}
          variants={inViewVariants}
          transition={{ type: "easeOut", duration: 1 }}
        >
          <GatsbyImage
            className="h-full"
            image={artistHerolImage}
            alt={""}
            objectFit={"inside"}
            objectPosition={"center center"}
          />
        </motion.div>
        <OverlayImage />
      </LinkArtist>

      <LinkIcon>
        <LinkArtist a={slug} classes={""}>
          <div className="mt-xl capitalize">
            {artist.firstName} {artist.lastName}
          </div>
        </LinkArtist>
      </LinkIcon>
    </div>
  );
};
