import * as React from "react";
import { ArtistsWorkBtn } from "../shared/artistsWorksBtn";

const AllArtistsNavbar = ({ location, classes }) => {
  return (
    /* --------- Artist/Work NAVBAR ---------  */
    <ArtistsWorkBtn location={location} classes={classes} />
  );
};

export default AllArtistsNavbar;
